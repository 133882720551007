@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@400;500;600&display=swap');
@import '../../../../scss/helpers/mixins';
@import '../../../../scss/helpers/variables';

$bodyColor: #2d2f42;
$grayColor: rgba($bodyColor, 0.6);
$borderColor: rgba($bodyColor, 0.1);
$danger: #ff5656;
$success: #10a75b;

.print-wrapper {
  width: 695px;
  height: 1080px;
  margin: 0 auto;
  font-family: 'IBM Plex Sans Arabic', sans-serif;
  font-size: 10.5px;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  padding: 0;
  color: $bodyColor;

  .text-neutral-60 {
    color: $grayColor !important;
  }

  .text-red-60 {
    color: $danger !important;
  }

  .text-green-60 {
    color: $success !important;
  }

  .border-neutral-20 {
    border-color: $borderColor !important;
  }
}

footer {
  margin-top: auto;
}

// hides the div with hardcoded details in the html
// Added this so its easier to read through the HTML code
// and not having to do it in the component.ts file
.hide {
  display: none;
}

.top-margin {
  margin-top: 100vh;
}

@media print {
  .rw-main {
    .rw-main-content {
      display: inline !important;
    }
    .rw-main-content-body {
      border: none !important;
    }
  }
  .print-wrapper {
    padding: 50px 0 0 0;
  }
  .page-break {
    page-break-after: always !important; /* Force a break before new content */
  }

  @page {
    margin-top: 0;
    margin-bottom: 0;
    size: A4;
    margin: 0;
  }

  @page :footer {
    display: none;
  }

  @page :header {
    display: none;
  }
}

.fs-22 {
  font-size: 22px !important;
}

.fs-7 {
  font-size: 9px !important;
}

.fs-footer {
  font-size: 8px !important;
}

.print-table {
  width: 100%;
  border-collapse: collapse;
  th,
  td {
    text-align: right;
    vertical-align: top;
    padding: 0 0 4px 0;
    &:not(:first-child) {
      padding-left: 4px;
    }
  }
  td {
    padding-top: 4px;
  }
  &.print-border-table {
    th,
    td {
      border-bottom: 1px solid $borderColor;
    }
  }
  &.print-item-table {
    th,
    td {
      &:last-child {
        width: 84px;
      }
      &:nth-last-child(2) {
        width: 71px;
      }
    }
  }
}

.bank-table {
  table-layout: fixed;
  th,
  td {
    padding-top: 8px;
    padding-bottom: 8px;
    &:nth-last-child(2) {
      width: 179px;
      word-spacing: 2px;
      text-align: left;
    }
  }
}

.terms-row {
  display: flex;
  gap: 24px;
  padding: 4px 0;
  font-size: 11.5px;
}

.tg-col {
  width: calc(50% - 12px);
  &:last-child {
    text-align: right;
  }
}

.payment-table {
  table-layout: fixed;
  th,
  td {
    width: 100%;
  }
}

.body {
  min-height: 642px;
  position: relative;
  > *:not(.watermark) {
    position: relative;
    z-index: 1;
  }
}

.watermark {
  position: absolute;
  z-index: 0;
  font-size: 92px;
  font-weight: bold;
  color: variableValues($red, '60');
  opacity: 0.08;
  transform: translate(-50%, -50%) rotate(-30deg);
  transform-origin: center;
  top: 50%;
  left: 50%;
  width: 100%;
}

.credit-note-watermark {
  text-align: center;
  font-size: 80px;
}

.qr-code {
  height: 192px;
  width: 192px;
  transform: translateY(0px);
}

.rtl {
  direction: rtl;
}
