import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rwa-filter-tag',
  templateUrl: './filter-tag.component.html',
  styleUrl: './filter-tag.component.scss',
  providers: [DatePipe],
})
export class FilterTagComponent {
  @Input() key: string;

  @Input() name: string;

  @Input() value: string[] | { start: string; end: string };

  @Input() type: 'date' | 'multi-select';

  @Output() callRemove: EventEmitter<string> = new EventEmitter();

  constructor(private datePipe: DatePipe) {}

  emitRemove(): void {
    this.callRemove.emit(this.key);
  }

  get displayValue(): string {
    if (this.type === 'multi-select' && Array.isArray(this.value))
      return this.value.join(', ');
    if (this.type === 'date') {
      const dateValue = this.value as { start: string; end: string };

      return `${this.datePipe.transform(new Date(dateValue.start), 'dd/MM/yyyy')} - ${this.datePipe.transform(new Date(dateValue.end), 'dd/MM/yyyy')}`;
    }
    return '';
  }
}
