export enum FeatureFlagEnum {
  AdminPanelV2 = 'admin-panel-v2',
  chargesWithCheckout = 'charges-with-checkout',
  ManualCreditNote = 'manual-credit-note',
  PartialPaymentPermission = 'partial-payment-permission',
  ResetAccountPermission = 'reset-account-permission',
  ActivateSubscriptionZatca = 'activate-subscription-zatca-2',
  ActivateSubscriptionZatcaDeprecated = 'activate-subscription-zatca',
  InvoiceAndCreditNotesListing = 'invoice-and-credit-notes-listing',
}
