import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export type DateFilterFormType = {
  selectedTimePeriod: { key: string };
  fromDate: Date;
  toDate: Date;
  timeOption: string;
  fromTime: Date | null;
  fromAmPm: string;
  toTime: Date | null;
  toAmPm: string;
};

@Component({
  selector: 'rwa-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
})
export class DateFilterComponent implements OnInit, OnDestroy {
  @Input() key: string;

  @Input() state: DateFilterFormType;

  @Output() apply: EventEmitter<DateFilterFormType> = new EventEmitter();

  @Output() clear: EventEmitter<string> = new EventEmitter();

  dateFilterForm: FormGroup;

  dateFilterToTimeSubscriber = null;

  dateFilterFromTimeSubscriber = null;

  options = [
    { key: 'Today' },
    { key: 'This Week' },
    { key: 'This Month' },
    { key: 'Last 3 Months' },
    { key: 'Specific Range' },
  ];

  stateOptions = [
    { label: 'AM', value: 'am' },
    { label: 'PM', value: 'pm' },
  ];

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.dateFilterForm = this.fb.group({
      selectedTimePeriod: [null, Validators.required],
      fromDate: [null, Validators.required],
      toDate: [null, Validators.required],
      timeOption: ['All Day', Validators.required],
      fromTime: [null],
      fromAmPm: ['am'],
      toTime: [null],
      toAmPm: ['am'],
    });

    if (this.state) {
      this.dateFilterForm.patchValue({
        selectedTimePeriod: this.state.selectedTimePeriod,
        fromDate: new Date(this.state.fromDate),
        toDate: new Date(this.state.toDate),
        timeOption: this.state.timeOption,
        fromTime: this.state.fromTime,
        fromAmPm: this.state.fromAmPm,
        toTime: this.state.toTime,
        toAmPm: this.state.toAmPm,
      });
    }

    // subscribe to when to time changes
    this.dateFilterToTimeSubscriber = this.dateFilterForm
      .get('toTime')
      ?.valueChanges.subscribe((time) => {
        if (time) {
          const hours = time.getHours();
          this.dateFilterForm.patchValue({
            toAmPm: hours >= 12 ? 'pm' : 'am',
          });
        }
      });
    this.dateFilterFromTimeSubscriber = this.dateFilterForm
      .get('fromTime')
      ?.valueChanges.subscribe((time) => {
        if (time) {
          const hours = time.getHours();
          this.dateFilterForm.patchValue({
            fromAmPm: hours >= 12 ? 'pm' : 'am',
          });
        }
      });
  }

  ngOnDestroy(): void {
    if (this.dateFilterToTimeSubscriber)
      this.dateFilterToTimeSubscriber.unsubscribe();

    if (this.dateFilterFromTimeSubscriber)
      this.dateFilterFromTimeSubscriber.unsubscribe();
  }

  onChangeTimePeriod(period: string): void {
    const now = new Date();
    const fromDate = this.setDate(
      period,
      this.dateFilterForm.get('fromDate')?.value || now,
    );
    const toDate = this.dateFilterForm.get('toDate')?.value || now;

    if (this.dateFilterForm.get('timeOption')?.value === 'Specific Time') {
      const fromTime = this.dateFilterForm.get('fromTime')?.value;
      const fromAmPm = this.dateFilterForm.get('fromAmPm')?.value;
      const toTime = this.dateFilterForm.get('toTime')?.value;
      const toAmPm = this.dateFilterForm.get('toAmPm')?.value;

      this.setTime(fromTime, fromAmPm, fromDate);
      this.setTime(toTime, toAmPm, toDate);
    } else {
      fromDate.setHours(0, 0, 0, 0);
      toDate.setHours(23, 59, 59, 999);
    }

    this.dateFilterForm.patchValue({
      fromDate,
      toDate,
    });
  }

  setDate(period: string, currentDate: Date): Date {
    const date = new Date(currentDate);
    switch (period) {
      case 'Today':
        date.setHours(0, 0, 0, 0);
        break;
      case 'This Week':
        date.setDate(date.getDate() - date.getDay());
        date.setHours(0, 0, 0, 0);
        break;
      case 'This Month':
        date.setDate(1);
        date.setHours(0, 0, 0, 0);
        break;
      case 'Last 3 Months':
        date.setMonth(date.getMonth() - 3);
        date.setDate(1);
        date.setHours(0, 0, 0, 0);
        break;
      default:
        break;
    }
    return date;
  }

  setTime(time: string, amPm: string, date: Date): void {
    if (!time) return;
    const dateObj = new Date(time);
    const hours = dateObj.getHours();
    let hour = hours;
    const minutes = dateObj.getMinutes();

    if (amPm === 'PM' && hour < 12) hour += 12;
    if (amPm === 'AM' && hour === 12) hour = 0;

    date.setHours(hour, minutes, 0, 0);
  }

  clearFilter(): void {
    this.dateFilterForm.reset();
    this.dateFilterForm.patchValue({
      timeOption: 'All Day',
      fromAmPm: 'am',
      toAmPm: 'am',
    });
    this.clear.emit(this.key);
  }

  applyFilter(): void {
    this.onChangeTimePeriod(
      this.dateFilterForm.get('selectedTimePeriod')?.value.key,
    );
    this.apply.emit(this.dateFilterForm.value);
  }

  onDateSelect(type: string): void {
    this.dateFilterForm.patchValue({
      selectedTimePeriod: { key: 'Specific Range' },
    });
    if (this.dateFilterForm.get('timeOption')?.value === 'All Day') {
      const setDate = new Date(this.dateFilterForm.get(type).value);
      if (type === 'fromDate') setDate.setHours(0, 0, 0, 0);
      else setDate.setHours(23, 59, 59, 999);

      this.dateFilterForm.patchValue({
        [type]: setDate,
      });
    }
  }

  onChangeTime(time: Date, type: string): void {
    if (type === 'from') {
      this.dateFilterForm.patchValue({
        fromTime: time,
      });
      const date = new Date(time);
      const hours = date.getHours();
      this.dateFilterForm.patchValue({
        fromAmPm: hours >= 12 ? 'pm' : 'am',
      });
    } else {
      this.dateFilterForm.patchValue({
        toTime: time,
      });
      const date = new Date(time);
      const hours = date.getHours();
      this.dateFilterForm.patchValue({
        toAmPm: hours >= 12 ? 'pm' : 'am',
      });
    }

    const selectedTimePeriod =
      this.dateFilterForm.get('selectedTimePeriod')?.value;
    if (selectedTimePeriod) this.onChangeTimePeriod(selectedTimePeriod.key);
  }

  onAmPmChange(value: 'am' | 'pm', type: 'from' | 'to'): void {
    if (type === 'from') {
      this.handleAmPmChange('fromTime', value);
    } else if (type === 'to') {
      this.handleAmPmChange('toTime', value);
    }
  }

  private handleAmPmChange(
    timeField: 'fromTime' | 'toTime',
    value: 'am' | 'pm',
  ): void {
    const time = this.dateFilterForm.get(timeField)?.value;
    if (time instanceof Date && !Number.isNaN(time.getTime())) {
      if (value === 'am' && time.getHours() >= 12) {
        time.setHours(time.getHours() - 12);
      } else if (value === 'pm' && time.getHours() < 12) {
        time.setHours(time.getHours() + 12);
      }
      this.dateFilterForm.patchValue({
        [timeField]: new Date(time),
      });
      this.onChangeTime(time, timeField === 'fromTime' ? 'from' : 'to');
    }
  }
}
